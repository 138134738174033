.App{
    /*margin: 0 70px;*/
}

.App .browse-more{
    margin: 36px 0 64px 0;
}

h1, h2, h3, h4, h5, h6, p{
    margin: 0;
    padding: 0;
}