@font-face {
  font-family: 'Cera Pro';
  src: local("CeraPro"),
  url("./fonts/cera-pro/Cera-Pro-Black.otf") format("truetype");
}

@font-face {
  font-family: 'Agenor';
  src: local("Agenor"),
  url("./fonts/agenor/Agenor-Thin.ttf") format("truetype");
}

html{
  width: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./images/background/background.png');
  background-repeat: no-repeat;
  background-color: #000;
  background-size: 100% 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
